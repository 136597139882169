<template>
  <div>
    <Header/> 
    <div class="contents_text">
      <!-- <router-link :to="{path:'/'}">
        <img class="contents_img_1" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="contents_img_2" src="../../assets/index/add_icon.png">
        <div calss="contents_study">{{share}}</div>
    </div>
    
    <div class="sv_1">
        <div v-for="item in type_content" :key="item">
            <!-- <router-link :to="{path:'/share_pic_sort'}"> -->
            <div class="flex-align-con indexbtn sort-title-con" @click="content(item.id,item.type)">
                <!-- 0:圖片 1:文字 -->
                <div :class="{'highlight-image-colors':item.type == 0,'highlight-image-colors_1':item.type == 1}">
                    <img :src="item.image">
                </div>
                <div style="margin-left: 10px; font-size: 26px; font-weight: 300;">{{item.title}}</div>
                <div class="highlight-image-color" style="margin-left: auto; margin-right: 10px;">
                    <img src="../../assets/index/arrow_d_icon.png">
                </div>
            </div>
            <!-- </router-link> -->
            <div v-if="types_content[item.id]">
                <div class="flex-align-con sort-list-item" v-for="items in types_content[item.id]" :key="items" @click="content(items.id,items.type)">
                    <div class="sort-list-item_div">{{items.title}}</div>
                    <div class="sort-list-item_img">
                        <img src="../../assets/index/arrow_d_icon.png" style="width: 18px;height: 18px;">
                    </div>
                </div>
            </div>
        </div>
        <div style="width:100%;height:90px;"></div>
        <div style="width:100%;height:90px;"></div>
    </div>

  </div>
</template>

<style>
.sort-list-item_div{
    font-size: 18px; font-weight: 300; margin-left: 20px;
}
.sort-list-item_img{
    margin-left: auto;margin-right: 10px;filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(38deg) brightness(98%) contrast(92%);
}
.sort-list-item{
    border-bottom: 1px solid rgba(97,97,97,.3);
    padding: 8px;
}
.highlight-image-color img{
    width: 35px;
    height: 35px;
}
.highlight-image-colors,.highlight-image-color,.highlight-image-colors_1 {
    filter: invert(61%) sepia(94%) saturate(186%) hue-rotate(352deg) brightness(88%) contrast(85%);
}
.highlight-image-colors img{
    width: 45px;
    height: 60px;
}
.highlight-image-colors_1 img{
    width: 60px;
    height: 60px;
}
.sort-title-con {
    padding: 8px 5px 8px 20px;
}
.indexbtn {
    border-top: 1px solid #C49B6C;
    border-bottom: 1px solid #C49B6C;
}
.flex-align-con {
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}
.contents{
  background:#806546;
}
  .sv_1{
    height: 93vh;
    width: 100%;
    background:#fff;
  }
  .contents_study{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_img_1{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .contents_img_2{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
import {apiShareTypes} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        type_content:[],
        types_content:[],
        share:'',
    }
  },
  created(){
    let that = this;
    let lang_id = this.$store.state.lang_id;
    if(lang_id == 1){
        this.share = this.$zh_ft('share');
    }else{
        this.share = this.$zh_jt('share');
    }

      apiShareTypes({
          lang_id:lang_id,
      }).then(res=>{
          if(res.data.data.length > 0){
            that.type_content = res.data.data;
            for(let i=0; i<res.data.data.length; i++){
                
                apiShareTypes({
                    lang_id:this.$store.state.lang_id,
                    parent_id:res.data.data[i].id
                }).then(ress=>{
                    if(ress.data.data.length > 0){
                        that.types_content[res.data.data[i].id] = ress.data.data;
                    }
                }).catch(errors=>{
                    console.log(errors);
                })
            }
          }
      }).catch(error=>{
          console.log(error);
      })
  },
  methods:{
    content(val,type){
        // 0图片 1,文字
        if(type == 0){
            this.$router.push({
                path:'/share_pic_sort',
                query:{
                    type:val
                }
            });
        }else{
            this.$router.push({
                path:'/share_text_sort',
                query:{
                    type:val
                }
            });
        }
    },
  }
}
</script>

